import React, {useState} from 'react';
import {useQuery, gql } from '@apollo/client';
import Menu from "../menu/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import CropFreeIcon from '@material-ui/icons/CropFree';
import QrDialog from "../Qr/QrDialog";
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {blueGrey, cyan, deepOrange, deepPurple, green} from "@material-ui/core/colors";

const GET_ORDER_HISTORY = gql`
    query{
        lastOrders {
            id,
            externalOrderId,
            delivered,
            acceptedPayments {
              type,
              amount
            },
            orderData {
              customerAddress
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        margin: theme.spacing(1),

    },

}));

const OrderHistory=(props)=>{
    const classes = useStyles();
    const [ data, setData ] = useState<Array<any>>([])

    const [ loadQrOrder, setLoadQrOrder ] = useState<string>("")

    useQuery(GET_ORDER_HISTORY, {onCompleted: ( data ) => {
            setData(data.lastOrders)
        },
    });

    return (
        <React.Fragment>
            <Menu title={'История задач'}/>
            <CssBaseline />
            <QrDialog externalOrderId={loadQrOrder} cancel={()=>{setLoadQrOrder("")}}/>

            <List className={classes.root}>

                {
                    data.map(({id, externalOrderId, delivered, acceptedPayments, orderData}) => (
                        <ListItem key={id}>

                            <ListItemText primary={`Заказ: ${externalOrderId}`}
                              secondary={
                                  <React.Fragment>
                                      <Typography
                                          component="p"
                                          variant="inherit"
                                          color="textSecondary"
                                      >
                                          {delivered ? `Доставлен: ${new Date(delivered).toLocaleString()}` : 'Закрыт админом'}
                                      </Typography>
                                      {
                                          orderData != undefined && orderData.customerAddress != undefined ?
                                              <Typography
                                                  component="p"
                                                  variant="inherit"
                                                  color="textSecondary"
                                                  style={{color: cyan[500]}}
                                              >
                                                  {`Адрес: ${orderData.customerAddress}`}
                                              </Typography>
                                              :
                                              null
                                      }
                                      { acceptedPayments != undefined ?
                                          acceptedPayments.reduce((all: number, p: any) => all + p.amount, 0) == 0 ?
                                              <Typography component="span" variant={"inherit"} style={{color: blueGrey[500], marginRight: 5}}>
                                                Без оплат
                                              </Typography>
                                              :
                                              acceptedPayments.map(({type, amount}) => (
                                                  amount > 0 &&
                                                  <Typography key={amount} component="span" variant={"inherit"} style={
                                                      type == 0 ? {color: deepOrange[500], marginRight: 5} :
                                                          type == 1 ? {color: deepPurple[500], marginRight: 5} :
                                                              type == 13 ? {color: green[500], marginRight: 5} : {}
                                                  }>

                                                      {
                                                          type == 0 ? `Нал: ${amount}` :
                                                              type == 1 ? `Безнал: ${amount}` :
                                                                  type == 13 ? `Интернет заказ` : ""
                                                      }
                                                    </Typography>
                                              ))

                                          :
                                          ""
                                      }
                                  </React.Fragment>
                              }
                            />
                            <ListItemAvatar style={{minWidth: 0}}>
                                {/*<Avatar>*/}
                                    <CropFreeIcon onClick={()=>{
                                        setLoadQrOrder(externalOrderId)
                                    }}/>
                                {/*</Avatar>*/}
                            </ListItemAvatar>

                        </ListItem>
                    ))

                }
            </List>


            {/*</List>*/}

            <Box mt={8}>
                {/*<Copyright />*/}
            </Box>
        </React.Fragment>

  );
}

export default OrderHistory;
