import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Link,
    Typography, useTheme
} from "@material-ui/core";
import OrderProductList from "./OrderProductList";
import OrderPayment from "./OrderPayment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import HotTubIcon from '@material-ui/icons/HotTub';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        heading: {
            fontSize: theme.typography.pxToRem(17),
            // fontWeight: theme.typography.fontWeightRegular,
        },
        details: {
            alignItems: 'center',
        },
        column: {
            flexBasis: '33.33%',
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        inlineHeader: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center'
        }
    }),
);

interface IOrderListItem {
    data: any
    toggle(number)
    expanded: number
    showQr(externalOrderId)
}

export default function OrderListItem(params: IOrderListItem) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const currentTime = new Date().getTime()
    const estimateForDelivery = Math.round((currentTime - new Date(params.data.acceptedForDelivery).getTime())/ 60000)
    const estimateForCook = Math.round((new Date(params.data.acceptedForDelivery).getTime() - new Date(params.data.orderData.externalOrderAcceptDatetime).getTime())/ 60000)
    const totalEstimateTime = estimateForCook + estimateForDelivery

    function getCookEstimateColor(time: number): string {
        const colors: [number, number, string][] = [
            [0, 10, '#66BB6A'],
            [10, 15, '#FDD835'],
            [15, 20, '#FFA726'],
            [20, 30, '#EF5350'],
            [30, 1000, '#AB47BC'],
        ];
        for (const color of colors) {
            if (color[0] <= time && color[1] >= time) return color[2];
        }
        return '#212121'
    }

    function getCourierEstimateColor(time: number): string {
        console.log(time)
        const colors: [number, number, string][] = [
            [0, 10, '#66BB6A'],
            [10, 20, '#FDD835'],
            [20, 30, '#FFA726'],
            [30, 40, '#EF5350'],
            [40, 1000, '#AB47BC'],
        ];
        for (const color of colors) {
            if (color[0] <= time && color[1] >= time) return color[2];
        }
        return '#212121'
    }

    function getOrderEstimateColor(time: number): string {
        const colors: [number, number, string][] = [
            [0, 20, '#66BB6A'],
            [20, 40, '#FDD835'],
            [40, 60, '#FFA726'],
            [60, 80, '#EF5350'],
            [80, 1000, '#AB47BC'],
        ];
        for (const color of colors) {
            if (color[0] <= time && color[1] >= time) return color[2];
        }
        return '#212121'
    }

    return (

        <Accordion expanded={params.expanded === params.data.id} onChange={(e)=>{params.toggle(params.data.id)}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}

            >
                <div className={classes.inlineHeader}>
                    <span><b>{params.data.orderData.externalOrderId.slice(-3) }</b></span>
                    {
                        params.expanded === params.data.id ?
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1vw'}}>
                                <div style={{display: 'flex', marginLeft: '1vw', alignItems: 'center', color: getCookEstimateColor(estimateForCook)}}>
                                    <HotTubIcon/>
                                    <span>({estimateForCook} мин)</span>
                                </div>
                                <div style={{display: 'flex', marginLeft: '1vw', alignItems: 'center', color: getCourierEstimateColor(estimateForDelivery)}}>
                                    <LocalShippingIcon/>
                                    <span>({ estimateForDelivery } мин)</span>
                                </div>

                            </div>
                            :
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1vw'}}>
                                <div style={{display: 'flex', marginLeft: '1vw', alignItems: 'center', color: getOrderEstimateColor(totalEstimateTime)}}>
                                    <QueryBuilderIcon/>
                                    <span>({totalEstimateTime} мин)</span>
                                </div>
                            </div>
                    }


                </div>

            </AccordionSummary>
            <Divider />
            <AccordionDetails style={{flexDirection: "column"}}>
                <Box component="span" display="block">
                    { params.data.orderData.externalOrderAcceptDatetime ?
                        <Typography>Принят в разработку:
                            <span> </span>
                            <span style={{color: theme.palette.secondary.main}}>{new Date(params.data.orderData.externalOrderAcceptDatetime).toLocaleString()}</span>
                        </Typography>
                        : null }
                    { params.data.acceptedForDelivery ?
                        <Typography>Принят в доставку:
                            <span> </span>
                            <span style={{color: theme.palette.primary.main}}>{new Date(params.data.acceptedForDelivery).toLocaleString()}</span>
                        </Typography>
                        : null }
                    { params.data.orderData.customerContact &&
                        <Typography>
                            Телефон:
                            <span> </span>
                            <Link href={`tel:${params.data.orderData.customerContact}`} color="secondary">
                                {params.data.orderData.customerContact}
                            </Link>
                            {/*<a href={`tel:${params.data.orderData.customerContact}`}>{params.data.orderData.customerContact}</a>*/}
                        </Typography>
                    }
                    { params.data.orderData.customerAddress &&
                        <Typography >
                            Адрес:
                            <span> </span>
                            <Link href={`https://maps.yandex.ru/?text=${params.data.orderData.customerAddress}`} color="secondary">
                                {params.data.orderData.customerAddress}
                            </Link>
                            {/*<a href={`https://maps.yandex.ru/?text=${params.data.orderData.customerAddress}`}>{params.data.orderData.customerAddress}*/}
                            {/*    /!*<a href={`https://maps.apple.com/maps?q=${params.data.customerAddress}`}>{params.data.customerAddress}</a>*!/*/}
                            {/*</a>*/}
                        </Typography>
                    }
                    { params.data.orderData.customerComment &&
                        <Typography >
                            Комментарий к заказу: {params.data.orderData.customerComment}
                        </Typography>
                    }
                </Box>





            </AccordionDetails>
            <Divider />
            <OrderProductList products={params.data.orderData.products}/>
            <Divider />
            <AccordionActions style={{justifyContent: "left"}}>
                <OrderPayment order={params.data.orderData} showQr={(id: string)=>params.showQr(id)}/>
            </AccordionActions>
        </Accordion>
        //
        // <div key={params.id}>
        //     <ListItem button onClick={handleClick}>
        //         <ListItemIcon>
        //             <ListAltIcon />
        //         </ListItemIcon>
        //         <ListItemText primary={`Заказ: ${params.data.externalOrderId}` } />
        //         {open ? <ExpandLess /> : <ExpandMore />}
        //     </ListItem>
        //     <Collapse in={open} timeout="auto" unmountOnExit>
        //         <Paper elevation={0} >
        //
        //             <p>Телефон: <a href={`tel:${params.data.customerContact}`}>{params.data.customerContact}</a></p>
        //             <p>Адрес:
        //                 <a href={`https://maps.google.com/?q=${params.data.customerAddress}`}>
        //                     <a href={`https://maps.apple.com/maps?q=${params.data.customerAddress}`}>{params.data.customerAddress}</a>
        //                 </a>
        //             </p>
        //             { params.data.customerComment && <p>Комментарий: {params.data.customerComment}</p> }
        //
        //
        //             <OrderProductList products={params.data.products}/>
        //             <OrderPayment payments={params.data.payments}/>
        //         </Paper>
        //
        //
        //     </Collapse>
        // </div>
    );
}
