import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {Button, Chip, Typography} from "@material-ui/core";
import AccordionActions from "@material-ui/core/AccordionActions";
import OrderBeforeCloseDialog from "./OrderBeforeCloseDialod";
import OrderAcceptPaymentsDialog from "./OrderAcceptPaymentsDialod";
import {blueGrey, green} from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clipContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

interface IOrderProductList {
    order: any
    showQr(externalOrderId)
}

export default function OrderPayment(params: IOrderProductList) {
    const classes = useStyles();
    const [openOrderBeforeCloseDialog, setOrderBeforeCloseDialog] = React.useState(false);
    const [openOrderAcceptPaymentsDialog, setOrderAcceptPaymentsDialog] = React.useState(false);

    const isOnlyInet = params.order.payments[0].type == 13 && params.order.payments.length == 1
    const isPayFree = params.order.payments.reduce((all: number, p: any) => all + p.amount, 0 ) == 0

    return (
        <>
            <OrderBeforeCloseDialog key={`OrderBeforeCloseDialog${params.order.externalOrderId}`} order={params.order} open={openOrderBeforeCloseDialog}
                                    confirm={()=>{setOrderBeforeCloseDialog(false);setOrderAcceptPaymentsDialog(true)}} cancel={()=>{setOrderBeforeCloseDialog(false)}}/>

            <OrderAcceptPaymentsDialog key={`OrderAcceptPaymentsDialog${params.order.externalOrderId}`} order={params.order} open={openOrderAcceptPaymentsDialog}
                                    confirm={(needQr: boolean)=>{setOrderAcceptPaymentsDialog(false); if (needQr) params.showQr(params.order.externalOrderId)}} cancel={()=>{setOrderAcceptPaymentsDialog(false)}}/>
            <Button
                size="small"
                color="secondary"
                style={{minWidth: 140}}
                onClick={()=>{
                    setOrderBeforeCloseDialog(true)
                }}>
                Завершить заказ
            </Button>
            <div className={classes.clipContainer}>
                { isPayFree ?
                    <Chip variant="outlined" size="small" label="Без оплат" style={{color: blueGrey[500], borderColor:blueGrey[500]}}/>
                    :
                    params.order.payments.map(({type, amount}) => (
                        amount > 0 &&
                        <Chip  key={`opaymnts${type+amount}`} variant="outlined" size="small" label={
                            type == 0 ? `Нал: ${amount}` :
                                type == 1 ? `Безнал : ${amount}` :
                                    type == 13 ? `Интернет заказ` : ""
                        } style={
                            type == 0 ? {color: "#FF5722", borderColor: "#FF5722"} :
                                type == 1 ? {color: "#9C27B0", borderColor: "#9C27B0"} :
                                    type == 13 ? {color: "#4CAF50", borderColor: "#4CAF50"} : {}
                        }/>
                    ))
                }
            </div>
        </>
    );

}