import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clipContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

interface IOrderBeforeCloseDialog {
    order: any
    open: boolean
    confirm()
    cancel()
}

export default function OrderBeforeCloseDialog(params: IOrderBeforeCloseDialog) {
    const isOnlyInet = params.order.payments[0].type == 13 && params.order.payments.length == 1
    const isPayFree = params.order.payments.reduce((all: number, p: any) => all + p.amount, 0 ) == 0

    const handleClickOpen = () => {
        params.confirm()
    };

    const handleClose = () => {
        params.cancel()
    };

    return (
        <Dialog
            open={params.open}
            onClose={handleClose}

        >
            <DialogTitle>{`Завершение заказа: ${params.order.externalOrderId}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        isOnlyInet || isPayFree ?
                            <>
                                Для завершения заказа перейдите к следующему шагу.
                            </>
                            :
                            <>
                                Для завершения заказа с клиента необходимо получить:
                                <Box component="span" display="block">
                                    {
                                        params.order.payments.map(({type, amount}) => (
                                            <Typography key={`paymnts${type+amount}`} style={
                                                type == 0 ? {color: "#FF5722", borderColor: "#FF5722"} :
                                                    type == 1 ? {color: "#9C27B0", borderColor: "#9C27B0"} : {}
                                            }>{
                                                type == 0 ? <>Наличными: <b>{amount}</b> р</> :
                                                    type == 1 ? <>Безналичными: <b>{amount}</b> р</> : null
                                            }
                                            </Typography>
                                        ))
                                    }
                                </Box>

                            </>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Отмена
                </Button>
                <Button onClick={handleClickOpen} color="primary" autoFocus>
                    Далее
                </Button>
            </DialogActions>
        </Dialog>
    );

}