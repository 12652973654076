import React, {useState} from 'react';
import './App.css';
import SignIn from "./SignIn/SignIn";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Orders from "./Orders/Orders";
import OrderHistory from "./Orders/OrderHistory";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import { createStore, applyMiddleware, Store } from 'redux';
import thunk from "redux-thunk"
import reducer from "./store/reducer";
import {Provider} from "react-redux";

const store: Store<ThemeState, ThemeAction> & {
    dispatch: DispatchType
} = createStore(reducer, applyMiddleware(thunk))

function App() {
    const [theme, setTheme] = useState(store.getState().theme)

    const themeLight = createMuiTheme({
        palette: {
            type: "light"
        }
    });
    const themeDark = createMuiTheme({
        palette: {
            type: "dark",

        },

    });

    store.subscribe(() => {
        setTheme(store.getState().theme)
    })

    return (
        <Router>
            <MuiThemeProvider theme={theme == 'light' ? themeLight : themeDark}>
                <Provider store={store}>
                    <div>
                        <Route path="/" exact component={Orders} />
                        <Route path="/signin" component={SignIn} />
                        <Route path="/history" component={OrderHistory} />
                    </div>
                </Provider>
            </MuiThemeProvider>
        </Router>
    )
}

export default App;
