import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, ApolloLink, createHttpLink, DefaultOptions, InMemoryCache, split} from '@apollo/client';
import fetch from 'cross-fetch';
import { ApolloProvider } from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {RetryLink} from "@apollo/client/link/retry";
import {onError} from "@apollo/client/link/error";
import { WebSocketLink } from '@apollo/client/link/ws';
import {getMainDefinition} from "@apollo/client/utilities";

interface ResponseError {
    statusCode: number;
    bodyText: string;
}

const recoveryLink = new RetryLink({
    delay: {
        initial: 0,
    },
    attempts: {
        max: 10,
        retryIf: (error: ResponseError, operation) => {
            if (error.statusCode != 200) {
                return false
            }
            return new Promise((resolve, reject) => {
                resolve(true);
            });
        }
    }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
            console.log(message)
            if (message === "Unauthorized" || message === "Authorization header not found." || message == "Forbidden") {
                localStorage.removeItem('token');
                window.location.href = "/signin"
            }
        });
    }
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});
// uri: 'ws://localhost:3000/graphql',
// uri: 'wss://api.courier.vipcrm.ru/graphql',
const wsLink = new WebSocketLink({
    uri: 'wss://api.courier.vipcrm.ru/graphql',
    // uri: 'ws://localhost:3000/graphql',
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: {
            authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : "",
        },
        connectionCallback: (error, result) => {
            if (error && (error['message'] == "Forbidden" || error['message'] == "authToken must be provided")) {
                localStorage.removeItem('token');
                window.location.href = "/signin"
            }
        }
    },

});

const httpLink = createHttpLink({
    uri: 'https://api.courier.vipcrm.ru/graphql',
    // uri: 'http://localhost:3000/graphql',
    credentials: 'same-origin',
    fetch
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

const client = new ApolloClient({
    link: ApolloLink.from([
        recoveryLink,
        authLink,
        errorLink,
        splitLink,

    ]),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
});

ReactDOM.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
