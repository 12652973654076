import React, {Dispatch, useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Drawer, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import AppsIcon from '@material-ui/icons/Apps';
import SendIcon from '@material-ui/icons/Send';
import {ReactReduxContext, useDispatch} from "react-redux";
import {changeTheme} from "../store/actionCreators";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

interface IMenuProps {
    title: string
}

export default function Menu(props: IMenuProps) {
    const classes = useStyles();
    const [openMenu, setOpenMenu] = useState(false)

    const {store} = useContext(ReactReduxContext)
    const [theme, setTheme] = useState(store.getState().theme)

    const dispatch: Dispatch<any> = useDispatch()


    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={()=>setOpenMenu(true)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {props.title}
                    </Typography>
                    {
                        theme == 'light' ?
                            <Brightness4Icon onClick={()=>{
                                dispatch(changeTheme('dark'))
                                setTheme('dark')
                            }}/>
                        : theme == 'dark' ?
                            <Brightness5Icon onClick={()=>{
                                dispatch(changeTheme('light'))
                                setTheme('light')
                            }}/>
                            : null
                    }
                </Toolbar>
            </AppBar>
            <Drawer anchor={"left"} open={openMenu} onClose={()=>setOpenMenu(false)}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.root}
                >
                    <ListItem button onClick={()=>{
                        window.location.href = '/'
                    }}>
                        <ListItemIcon>
                            <AppsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Мои задачи" />
                    </ListItem>
                    <ListItem button onClick={()=>{
                        window.location.href = '/history'
                    }}>
                        <ListItemIcon>
                            <SendIcon />
                        </ListItemIcon>
                        <ListItemText primary="История заказов" />
                    </ListItem>
                    {/*<ListItem button>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <AccessTimeIcon />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Время прибытия" />*/}
                    {/*</ListItem>*/}
                    <ListItem button onClick={()=>{
                        localStorage.clear()
                        window.location.href = "/"
                    }}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Выход" />
                    </ListItem>

                </List>
            </Drawer>
        </div>
    );
}