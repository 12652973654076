import React, {useEffect, useState} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CloseIcon from '@material-ui/icons/Close';
import { Headers } from 'cross-fetch'
import {gql, useSubscription} from "@apollo/client";

global.Headers = global.Headers || Headers

const CREATE_DOCUMENT_SUBSCRIPTION = gql`
    subscription ($externalOrderId: String!) {
      documentCreated(externalOrderId: $externalOrderId){
        externalOrderId,
        qrCode
      }
    }
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clipContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

interface IQrDialog {
    externalOrderId: any
    cancel()
}

interface ILazyQR {
    externalOrderId: string
}

function LazyQR(props: ILazyQR){
    const [loadOrder, setLoadOrder] = useState<string>("")

    useEffect(() => {
        if (props.externalOrderId != '') setLoadOrder(props.externalOrderId)
    }, [props.externalOrderId])

    const { data, loading, error } = useSubscription(
        CREATE_DOCUMENT_SUBSCRIPTION,
        { variables: { externalOrderId: loadOrder }, shouldResubscribe: true}
    );

    if (loading) {
        return (
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Typography align={"center"}>Ожидайте QR код, затем, предложите клиенту его отсканировать. Код можно отсканировать приложением способным распознавать QR коды соответствующие 54-ФЗ</Typography>
                <CircularProgress style={{margin: 20}}/>
            </Box>
        );
    };

    if (error) {
        return <div>Error! {error.message}</div>;
    };

    return (
            loadOrder != '' && !loading ?
                <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <img src={data.documentCreated.qrCode} style={{alignSelf: "stretch", flexWrap: "wrap"}} />
                    <Typography align={"center"}>Отсканируйте код приложением поддерживающим распознавание QR кодов соответствующих 54-ФЗ</Typography>
                </Box>
                :
                null
    )
}

export default function QrDialog(params: IQrDialog) {
    return (
        <Dialog
            open={params.externalOrderId != ""}
            onClose={()=>{params.cancel()}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText align={"center"}>
                    QR для заказа {params.externalOrderId}
                </DialogContentText>
                <Box style={{display: 'flex', flexDirection: "column"}}>
                    <LazyQR externalOrderId={params.externalOrderId}/>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={()=>{params.cancel()}}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );

}