import React, {useEffect, useState} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, Grid, Input, TextField,
    Typography, withStyles
} from "@material-ui/core";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/Money';
import Divider from "@material-ui/core/Divider";

import MuiPhoneNumber from "material-ui-phone-number";

import {green, deepOrange, deepPurple, red, cyan} from '@material-ui/core/colors';
import {gql, useMutation} from "@apollo/client";
import Alert from "@material-ui/lab/Alert";

const SUBMIT = gql`
    mutation confirmOrder($externalOrderId: String!, $needSurrender: Boolean!, $cash: Float!, $cashless: Float!, $advance: Float!, $customerContact: String!) {
        confirmOrder(
            advance: $advance
            cashless: $cashless
            cash: $cash
            needSurrender: $needSurrender
            externalOrderId: $externalOrderId,
            customerContact: $customerContact
        ) {
            id,
            externalOrderId
        }
    }
`;

interface IOrderAcceptPaymentsDialog {
    order: any
    open: boolean
    confirm(needQr: boolean)
    cancel()
}

export default function OrderAcceptPaymentsDialog(params: IOrderAcceptPaymentsDialog) {
    const [error, setError] = useState("")

    const [lock, setLock] = useState(false)

    const [ submit ] = useMutation(SUBMIT);

    const [totalOrderAmount, setTotalOrderAmount] = useState<number>(0);

    const [cashless, setCashless] = useState<number>(0)
    const [cash, setCash] = useState<number>(0)
    const [advance, setAdvance] = useState<number>(0)


    const [needSurrender, setNeedSurrender] = useState<boolean>(false)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)

    const [needETicket, setNeedETicket] = useState<boolean>(false)
    const [customerContact, setCustomerContact] = useState<string>()
    const [customerContactValid, setCustomerContactValid] = useState<boolean>()

    const [isPartialInet, setIsPartialInet] = useState<boolean>(false)
    const [isOnlyInet, setIsOnlyInet] = useState<boolean>(false)
    const [isPayFree, setIsPayFree] = useState<boolean>(false)



    useEffect(()=>{
        if (!params.open) return
        let cashT = 0
        let cashlessT = 0
        let advanceT = 0
        params.order.payments.forEach((orderPayment)=>{
            switch (orderPayment.type) {
                case 0:
                    cashT += parseInt(orderPayment.amount)
                    break;
                case 1:
                    cashlessT += parseInt(orderPayment.amount)
                    break;
                case 13:
                    advanceT += parseInt(orderPayment.amount)
                    break;
            }
        })

        setCashless(cashlessT)
        setCash(cashT)
        setAdvance(advanceT)
        setTotalOrderAmount(cashT + cashlessT)
        setIsPartialInet((cashT > 0 || cashlessT > 0) && advanceT > 0)
        setIsOnlyInet(cashT == 0 && cashlessT == 0 && advanceT > 0)
        setIsPayFree(cashT == 0 && cashlessT == 0 && advanceT == 0)

    }, [params.open])

    const handleSubmitOrder = (needQr: boolean) => {

        // submit({ variables: { externalOrderId: params.order.externalOrderId, needSurrender: needSurrender, cash: needSurrender ? totalOrderAmount - cashless :cash, cashless: cashless, advance: advanceT } }).then(r=>{
        if (!lock){
            setLock(true)

            submit({ variables: { externalOrderId: params.order.externalOrderId, needSurrender: needSurrender, cash: cash, cashless: cashless, advance: advance, customerContact: needETicket ? customerContact: '' } }).then(r=>{
                setShowConfirmationDialog(false);
                setLock(false)
                params.confirm(needQr)
            }).catch(e=>{
                console.log(e.message)
                setError(`Ошибка. Срочно сфотографируйте или сделайте скриншот, и передайте Администратору. ${e.message}`)
                setTimeout(()=>{
                    setError('')
                    setLock(false)
                }, 60000)
            })
        }

    };

    return (
        <>
            <Dialog
                open={params.open}
                onClose={()=>params.cancel()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Завершение заказа: ${params.order.externalOrderId}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            isOnlyInet ?
                                <>
                                    Завершите заказ и выдайте товар клиенту. Чек придет на электронный адрес клиента.
                                </>
                                : isPayFree ?
                                <>
                                    Завершите заказ и выдайте товар клиенту. Затем дождитесь появления QR кода, и предложите клиенту его отсканировать.
                                </>
                                :
                                <>
                                    { isPartialInet ?
                                        <>Ранее были заявлены следующие способы оплаты, если не верно, внесите корректировку, затем закройте заказ и передайте товар клиенту. Чек придет на электронный адрес клиента.</>
                                        :
                                        <>Ранее были заявлены следующие способы оплаты, если не верно, внесите корректировку. При необходимости отправьте клиенту чек в СМС. Затем закройте заказ и дождитесь появления QR кода. Предложите клиенту его отсканировать (если не отправили в СМС).</>
                                    }

                                    <Divider style={{marginTop: 10}}/>

                                        <Grid container spacing={1} alignItems="flex-end" style={{marginTop: 10}}>
                                            <Grid item>
                                                <MoneyIcon style={{color: "#FF5722", borderColor: "#FF5722"}}/>
                                            </Grid>
                                            <Grid item>
                                                <TextField id="input-with-icon-grid"
                                                           label='Наличными'
                                                           value={cash > 0 ? cash.toString(): ""}
                                                           onChange={(e)=>setCash(parseInt(e.target.value ) || 0)}
                                                           style={{color: "#FF5722", borderColor: "#FF5722", width: 120}}
                                                />

                                            </Grid>

                                            <Grid item>
                                                <FormControlLabel
                                                    control={<Checkbox checked={needSurrender} onChange={(e)=>setNeedSurrender(e.target.checked)}/>}
                                                    label="Сдача"
                                                />
                                            </Grid>

                                        </Grid>
                                        <Grid container spacing={1} alignItems="flex-end" style={{marginTop: 10}}>
                                            <Grid item>
                                                <CreditCardIcon style={{color: "#9C27B0", borderColor: "#9C27B0"}}/>
                                            </Grid>
                                            <Grid item>
                                                <TextField id="input-with-icon-grid"
                                                           label='Безналичными'
                                                           value={cashless > 0 ? cashless.toString(): ""}
                                                           onChange={(e)=>setCashless(parseInt(e.target.value ) || 0)}
                                                           style={{color: "#9C27B0", borderColor: "#9C27B0"}}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} alignItems="flex-end" style={{marginTop: 10, marginLeft: 2}}>
                                            <Grid container style={{flexDirection: "column"}}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={needETicket} onChange={(e)=>{setNeedETicket(e.target.checked); setCustomerContact(params.order.customerContact)}}/>}
                                                    label="Выслать чек в СМС"
                                                />
                                                {
                                                    needETicket &&
                                                    <MuiPhoneNumber
                                                        name="phone"
                                                        label="Номер телефона получателя"
                                                        data-cy="user-phone"
                                                        defaultCountry={"ru"}
                                                        onlyCountries={['ru']}
                                                        value={customerContact}
                                                        onChange={(value, c)=>{
                                                            console.log(value, c)
                                                            setCustomerContact(value)
                                                        }}
                                                        placeholder={'+7 (999) 999-99-99'}
                                                        isValid={(v)=> {
                                                            if (/^((7)+([0-9]){10})$/i.test(v)) {
                                                                setCustomerContactValid(true)
                                                                return true
                                                            }
                                                            setCustomerContactValid(false)
                                                            return false
                                                        }}
                                                        style={{width: 250}}
                                                    />
                                                }

                                            </Grid>

                                        </Grid>
                                </>
                        }
                    </DialogContentText>

                    {
                        !isOnlyInet && !isPayFree &&
                            <>
                                <Divider style={{marginBottom: 10}}/>
                                <Typography>
                                    Необходимо принять: <b>{totalOrderAmount}</b> р
                                </Typography>
                                <Typography>
                                    Внесено: <b>{cash+cashless}</b> р
                                </Typography>

                                {   needSurrender &&
                                <Typography>
                                    Сдача: <b>{cash - (totalOrderAmount - cashless)}</b> р
                                </Typography>
                                }
                            </>
                    }

                </DialogContent>
                <Divider component={'span'}/>
                <DialogActions>
                    <Button onClick={()=>params.cancel()} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={()=>{
                                if(isOnlyInet) handleSubmitOrder(false)
                                else if(isPayFree) handleSubmitOrder(true)
                                else setShowConfirmationDialog(true)
                            }} color="primary"
                            disabled={
                                totalOrderAmount != ((needSurrender ? (cash > (totalOrderAmount - cashless) ? (totalOrderAmount - cashless) : cash) : cash) + cashless) ||
                                cashless > totalOrderAmount || (cashless == totalOrderAmount && needSurrender) || lock ||
                                needETicket && !customerContactValid
                            }
                    >
                        Далее
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showConfirmationDialog}
                onClose={()=>{setError(''); setShowConfirmationDialog(false); setLock(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Завершение заказа: ${params.order.externalOrderId}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Проверьте правильность введенных данных перед отправкой:
                        <>
                            {
                                cash > 0 && <Typography style={{color: deepOrange[500]}}>Наличными: <b>{cash}</b> р</Typography>
                            }
                            {
                                cashless > 0 && <Typography style={{color: deepPurple[500]}}>Безналичными: <b>{cashless}</b> р</Typography>
                            }
                            {
                                needSurrender && <Typography style={{color: green[500]}}>Сдача: <b>{cash - (totalOrderAmount - cashless)}</b> р</Typography>
                            }
                            {
                                needETicket && <Typography style={{color: cyan[500]}}>Адрес отправки чека: <b>{customerContact}</b></Typography>
                            }
                        </>

                    </DialogContentText>
                </DialogContent>
                {
                    error && <><Divider/><Alert severity="error">{error}</Alert></>
                }
                {
                    lock && !error && <><Divider/><Alert severity="info">Выполняем. Ожидайте...</Alert></>
                }
                <Divider />
                <DialogActions>
                    <Button onClick={()=>{setError(''); setShowConfirmationDialog(false); setLock(false)}} style={{color: red[800]}}>
                        Изменить оплаты
                    </Button>
                    <Button onClick={()=>handleSubmitOrder(true)} disabled={lock} style={{color: green[800]}}>
                        Да, все верно
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}