import * as actionTypes from "./actionTypes"

const initialState: ThemeState = {
    theme: localStorage.getItem('theme') || 'light',
}

const reducer = (
    state: ThemeState = initialState,
    action: ThemeAction
): ThemeState => {
    switch (action.type) {
        case actionTypes.CHANGE_THEME:
            localStorage.setItem('theme', action.theme)
            return {
                ...state,
                theme: action.theme
            }

    }
    return state
}

export default reducer