import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

interface IOrderProductList {
    products: any
}

export default function OrderProductList(params: IOrderProductList) {
    const classes = useStyles();

    return(
        <List>
            { params.products.map(({name, amount}) => (
                <ListItem  key={`lit${name}`}>
                    <ListItemText
                        primary={name}
                        secondary={`Колличество: ${amount} шт.`}
                    />
                </ListItem>
            ))
            }
        </List>
    )
}